import React from 'react'
import { Row, useTable } from 'react-table'
import NoData from 'shared/noData'
import Pagination from 'shared/pagination/Pagination'
import { CardLoader } from 'shared/skeletonLoaders'
import { Col, Row as FlexRow } from 'shared/styled'
import { SubTable, TableData } from './style'

interface TableProp {
  data?: any
  columns?: any
  subTableHead?: any
  expandedRows?: { [key: string]: boolean } // Object to track expanded row state
  tableIndex?: number
  currentPage?: number
  totalPages?: number
  handlePageChange?: any
  subTableLoading?: boolean
}

export const CustomTable: React.FC<TableProp> = ({
  data,
  columns,
  subTableHead,
  expandedRows,
  tableIndex = 0,
  currentPage = 1,
  totalPages = 1,
  handlePageChange,
  subTableLoading,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })

  const renderSubTable = <D extends object = {}>(row: Row<D>) => {
    //@ts-ignore
    return row?.original?.subRows?.length ? (
      <>
        <SubTable className='subTable'>
          <thead>
            <tr>
              {subTableHead?.map((subColumn: any) => (
                <th key={subColumn?.accessor}>{subColumn.Header()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* @ts-ignore */}
            {row?.original?.subRows?.map((subRow: any, subRowIndex: number) => (
              <tr key={subRowIndex}>
                {subTableHead?.map((subColumn: any) => (
                  <td key={subColumn.accessor}>{subRow[subColumn.accessor]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </SubTable>

        {totalPages > 1 && (
          <FlexRow justify='end' padding='10px'>
            <Pagination
              pageLimit={5}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </FlexRow>
        )}
      </>
    ) : (
      <NoData />
    )
  }

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th
                className='th'
                {...column.getHeaderProps()}
                style={{ textAlign: 'start' }}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>        {rows.map((row: Row<{}>) => {
          prepareRow(row)
          return (
            <React.Fragment key={row.id}>
              <tr {...row.getRowProps()} style={{ textAlign: 'start' }}>
                {row.cells.map((cell: any, index: number) => (
                  <TableData
                    {...cell.getCellProps()}
                    style={{ textAlign: 'start' }}
                    key={cell.getCellProps().key}
                  >
                    {cell.render('Cell')}
                  </TableData>
                ))}
              </tr>

              {expandedRows?.[`${tableIndex}_${row.id}`] ? (
                <tr>
                  <td
                    colSpan={columns.length}
                    className='subTableContainer'
                    style={{
                      padding: '0',
                    }}
                  >
                    {subTableLoading ? (
                      <Col gap='4px'>
                        {Array(2)
                          .fill(0)
                          .map((_, i) => (
                            <CardLoader
                              variant='subTableRow'
                              key={`${i + 1}`}
                            />
                          ))}
                      </Col>
                    ) : (
                      renderSubTable(row)
                    )}
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          )
        })}
      </tbody>
    </table>
  )
}
