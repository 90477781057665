import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  clearUserReportFilters,
  setInitialUserReportFilters,
  setUserReportColumns,
} from '../actions/userReport'
import { filters } from 'modules/pages/userReport/filterConstants'

// Define the type for the action payload
interface ActionPayload {
  column: string
  keepAdding?: boolean
}

// Define the state type, which can be either null or an array of strings
type UserReportState = string[] | null

const dataKeys: string[] = filters.map((filter: any) => filter.dataKey)
const initialState: UserReportState = dataKeys

// Create the reducer with a builder callback
const userReportReducer = createReducer(initialState, (builder) => {
  //@ts-ignore
  builder.addCase(
    setUserReportColumns,
    (state: UserReportState, action: PayloadAction<ActionPayload>) => {
      const { column, keepAdding } = action.payload

      if (state === null) {
        return [column]
      }

      if (!state.includes(column)) {
        return [...state, column]
      }

      // At this point, state is not null and includes the column
      if (keepAdding) {
        // If keepAdding is true, do not remove the column, just return the state
        return state
      }

      // If keepAdding is false, remove the column
      const newState = state.filter((item: string) => item !== column)
      return newState.length > 0 ? newState : null
    }
  )

  // Clear all
  //@ts-ignore
  builder.addCase(clearUserReportFilters, () => {
    return ['last_login']
  })
  //set initial values
  //@ts-ignore
  builder.addCase(setInitialUserReportFilters, () => {
    return initialState
  })
})

export default userReportReducer
