import { AUCTION, CANCEL, LIST, MINT, OFFER, SALE, TRANSFER } from 'utils'
import { numberRegex } from 'utils/regexPatterns'
import { ISelectableOption } from 'utils/type'

export const ALL = 'ALL'
export const ON_AUCTION = 'ON_AUCTION'
export const FIXED_PRICE = 'FIXED_PRICE'
export const XSTRELA_PLATFORM = 'XSTRELA_PLATFORM'
export const OUTSIDE_XSTRELA = 'OUTSIDE_XSTRELA'
export const EXTERNAL = 'External'
const ETHEREUM_NETWORK = 'ethereum'
const SOLANA_NETWORK = 'solana'

export const XSTRELA = 'XSTRELA'
export const OPENSEA = 'OPENSEA'
export const salesTypeOptions: ISelectableOption[] = [
  { label: 'All', slug: ALL },
  {
    label: 'Auction',
    slug: AUCTION,
  },
  { label: 'Sale', slug: SALE },
  { label: 'Mint', slug: MINT },
]

export const platformTypeOptions: ISelectableOption[] = [
  { label: 'All', slug: ALL },
  { label: 'Xstrela', slug: XSTRELA },
  { label: EXTERNAL, slug: OPENSEA },
]

export const playableTypeOptions: any[] = [
  { label: 'All', slug: ALL },
  { label: 'Yes', slug: 'yes' },
  { label: 'No', slug: 'no' },
]
export const assetPlatformTypeOptions: ISelectableOption[] = [
  { label: 'All', slug: ALL },
  { label: 'Xstrela', slug: XSTRELA_PLATFORM },
  { label: 'OutSide Xstrela', slug: OUTSIDE_XSTRELA },
]

export const allPlatforms = [XSTRELA, OPENSEA]

export const chainTypeOptions: ISelectableOption[] = [
  { label: 'All', slug: ALL },
  { label: 'Ethereum', slug: ETHEREUM_NETWORK },
  { label: 'Solana', slug: SOLANA_NETWORK },
]

export const eventTypeOptions: ISelectableOption[] = [
  { label: 'Sale', slug: SALE },
  { label: 'Listing', slug: LIST },
  { label: 'Offer', slug: OFFER },
  { label: 'Transfer', slug: TRANSFER },
  { label: 'Cancel', slug: CANCEL },
  { label: 'Mint', slug: MINT },
]

export const priceChainTypeOption = [
  { label: 'ETH', type: '2', slug: 'ethereum' },
  { label: 'SOL', type: '1', slug: 'solana' },
]
export const priceChainTypeOptionAll = [
  { label: 'USD', type: '3', slug: ALL },
  { label: 'ETH', type: '2', slug: 'ethereum' },
  { label: 'SOL', type: '1', slug: 'solana' },
]

export const filterContainerWidth = '377px'

export const userTypeOptions = [
  { label: 'All', type: '1', slug: ALL },
  { label: 'Players', type: '2', slug: 'PLAYER' },
  { label: 'Publishers', type: '3', slug: 'PUBLISHER' },
]

export const priceChangeHandler = (setState: Function) => {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    const isValid = numberRegex.test(inputValue)
    if (isValid) {
      setState(Number(inputValue))
    }
  }
}

export const handleValueChange = (
  setState: Function,
  property: 'min' | 'max'
) => {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    const isValid = numberRegex.test(inputValue)
    if (isValid) {
      setState((prevState: any) => ({
        ...prevState,
        [property]: Number(inputValue),
      }))
    }
  }
}
